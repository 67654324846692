<template>
    <layout>
        <div class="track mt-5 px-md-10 mb-14 mb-md-0">
            <div class="d-flex justify-space-between">
                <h1 class="pr-10 heading-2">Track shipping</h1>
                <v-btn x-large class="secondary px-12 py-8 text-14 text-transform-none d-none d-md-flex" depressed :to="{name: 'account.quote'}">Start new quote</v-btn>
            </div>
            <v-select
                dense
                outlined
                :items="tabs"
                item-text="name"
                item-value="index"
                v-model="tab"
                class="d-md-none"
            >
            </v-select>
            <v-tabs v-model="tab" background-color="bg-transparent d-none d-md-block mt-5">
                <v-tab v-for="item in tabs" :key="item.key" class="text-14-45 text-transform-none px-3 mx-0" :ripple="false">
                    {{ item.name }}
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" class="bg-transparent">
                <v-tab-item v-for="item in tabs" :key="item.key">

                    <v-row>
                        <v-col cols="12" lg="5">
                            <v-card class="px-3 px-md-7 py-4 mt-md-7" flat v-for="(order, i) in orders[item.key]" :key="i">

                                <v-card-text class="pa-0">
                                    <div class="top d-flex flex-column flex-md-row justify-space-between mb-4">
                                        <div class="order-1 order-md-0">
                                            <div class="text-20-75 mb-1">
                                                #{{ order.id }}
                                            </div>
                                            <div class="text-14 mt-4 mt-md-0">
                                                Carier: {{ order.is_carrier ? order.carrier.name : 'Freight Crew Inc.' }}
                                            </div>
                                            <div class="text-14 mb-4 d-block d-md-none">Ship date: {{ order.items[0].date }}</div>
                                        </div>
                                        <div class="text-md-right order-0 order-md-1 mb-6 mb-md-0">
                                            <span v-if="!order.status.is_active" class="badge py-2 px-4 shipped">Shipped</span>
                                            <span v-if="order.status.is_active" class="badge py-2 px-4 active ">Active</span>
                                            <div class="text-14 my-4 d-none d-md-block">Ship date: {{order.items[0].date}}</div>
                                        </div>
                                    </div>
                                    <div class="content">
                                        <div class="text-16 mb-4">
                                            {{ getCity(order) }} <v-icon>mdi-chevron-right</v-icon> {{ getCity(order, 'to') }}
                                        </div>
                                        <div v-for="item in filterItems(order)">
                                            <div class="text-14-60 mb-1">
                                                {{ item.description}}
                                            </div>
                                            <div class="text-14 mb-3">
                                                {{ item.quantity }} Bundles, {{ item.quantity * item.weight }} pounds
                                            </div>
                                        </div>
                                        <v-divider></v-divider>
                                        <div class="d-flex justify-space-between align-center mt-5">
                                            <div class="">
                                                <img v-if="order.lastlog" src="@/assets/icons/svg/timer.svg" alt="">
                                                <span class="ml-3" v-if="order.lastlog" v-html="order.lastlog.log"></span>
                                            </div>
                                            <v-btn text :ripple="false" class="text-transform-none blue-text text-14-60" depressed @click="selectedOrder = order">Map view</v-btn>
                                        </div>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col v-if="selectedOrder" cols="12" lg="7" class="pl-10">
                            <v-card class="px-7 py-4 mt-7" flat>
                                <v-card-text class="pa-0">
                                    <div class="top d-flex flex-row justify-space-between mb-4">
                                        <div class="">
                                            <div class="text-18-75 mb-1">
                                                {{ getCity(selectedOrder) }} <v-icon>mdi-chevron-right</v-icon> {{ getCity(selectedOrder, 'to') }}
                                            </div>
                                            <div class="silver--text text-14">
                                                Carier: {{ selectedOrder.is_carrier ? selectedOrder.carrier.name : 'Freight Crew Inc.' }}
                                            </div>
                                        </div>
                                        <div class="text-right">
                                            <div class="blue-text mb-1">
                                                <img src="@/assets/icons/svg/timer-blue.svg" alt="">
                                                <span class="ml-3" v-if="selectedOrder.lastlog" v-html="selectedOrder.lastlog.log"></span>
                                            </div>
                                            <div class="text-14 mb-4">Ship day: {{selectedOrder.items[0].date}}</div>
                                        </div>
                                    </div>
                                    <div class="content">
                                        <v-tabs
                                            v-model="card_tab"
                                            background-color="bg-transparent"
                                        >
                                            <v-tab v-for="(item, i) in card_tabs" :key="i" class="text-transform-none text-14-45 px-2">
                                                {{ item }}
                                            </v-tab>
                                        </v-tabs>

                                        <v-tabs-items v-model="card_tab">
                                            <v-tab-item>
                                                <v-card flat>
                                                    <v-card-text class="px-0">Map Coming Soon</v-card-text>
                                                </v-card>
                                            </v-tab-item>
                                            <v-tab-item>
                                                <v-card flat>
                                                    <v-card-text class="px-0">
                                                        <vertical-list :item="prepareLogs(selectedOrder.logs)"/>
                                                    </v-card-text>
                                                </v-card>
                                            </v-tab-item>
                                        </v-tabs-items>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-tabs-items>

        </div>
    </layout>
</template>

<script>
import Vue from 'vue';
import Layout from '@/components/layouts/AccountLayout.vue'
import VerticalList from '@/components/base/VerticalList.vue'
export default {
    middleware: 'auth',
    components: {
        Layout,
        VerticalList
    },
    data: () => ({
        tab: 0,
        tabs: [
            {
                index: 0,
                key: 'all',
                name: 'All shipments'
            },
            {
                index: 1,
                key: 'active',
                name: 'Active'
            },
            {
                index: 2,
                key: 'shipped',
                name: 'Shipped'
            },
        ],
        orders: {
            all: [],
            active: [],
            shipped: [],
        },
        selectedOrder: null,
        card_tab: 0,
        card_tabs: [
            'Map tracking',
            'History'
        ],
        history: [
            {
                text: 'Loaded from the pickup place',
                date: '08/23/2020 10:12AM',
            },
            {
                text: 'Left «LA Internation» airport',
                date: '08/23/2020 10:12AM'
            },
            {
                text: 'Arrived at customs',
                date: '08/23/2020 10:12AM'
            }
        ]
    }),
    mounted(){
        this.getLoads('orders');
    },
    methods: {
        async getLoads(type = 'orders', take = 6, skip = 0){
            var uri = process.env.VUE_APP_API + 'loads/v1/' + type;
            var tmp = {};
            await this.axios.get(uri).then(response => {
                this.orders.all = Object.assign({}, this.orders.all, response.data);
                response.data.map((item) => {
                    if(item.id_status == 13)
                        return;
                    if(item.id_status == 11 || item.id_status == 12){
                        this.orders.shipped.push(Vue.util.extend({}, item));
                        return;
                    }

                    this.orders.active.push(Vue.util.extend({}, item));
                })

            }).catch();


        },
        dateFormat(toFormat = false, dayPlus = 0){
            var obj = new Date();
            if(toFormat)
                obj = new Date(toFormat);
            let month = obj.getMonth() + 1;
            let date = obj.getDate() + dayPlus;
            let year = obj.getFullYear();
            return month + '/' + date + '/' + year;
        },
        dateCompare(date1, date2, compare){
            let d1 = new Date(date1)
            let d2 = new Date(date2)

            if(compare == '>'){
                return d1 > d2;
            }
            if(compare == '<'){
                return d1 < d2;
            }
        },
        filterItems(model){
            let items = model.items.filter(function(item){
                return item.is_shipper == 1;
            })
            return items
        },
        getCity(model, type = 'from'){
            let warehouse = model.items.filter(function(item){
                if(type == 'from')
                    return item.is_shipper == 1;
                return item.is_shipper == 0;
            })

            if(warehouse[0])
                return warehouse[0].warehouse.state + ', ' + warehouse[0].warehouse.city;
            return 'not set'
        },
        filterShipped(){
            this.orders.filter((item) => {
                return item.id_status == 11 || item.id_status == 12;
            })
        },
        prepareLogs(model){
            return model.map((item) => {
                return {
                    text: item.log,
                    date: this.dateFormat(item.updated_at)
                }
            })
        }

    }
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
.v-card{
    .top{
        border-bottom: 1px dashed #CDE0FF;

    }
    .content{
        .mw-60{
            max-width: 60%;
        }
    }
}
.track{
    .v-slide-group__content.v-tabs-bar__content{
        border-bottom: 2px solid #CDE0FF;
        .v-tabs-slider{
            margin-top: 2px;
        }
        .v-tab:not(.v-tab--active){
            color:$dark!important;
        }
    }
    .vertical-list{
        .v-list-item{
            .v-list-item__icon{
                background-color: #fff;
                z-index: 10;
                img{
                    opacity: 0.5
                }
            }
        }
        .v-list-item:last-of-type{
            .v-list-item__icon{
                img{
                    opacity: 1;
                }
            }
        }
        .iconafterdash{
            padding-left: 0;
        }
        .iconafterdash::before{
            display: block;
            background-color: transparent;
            border-right: 2px solid #CDE0FF;
            content: " ";
            height: 100%;
            position: absolute;
            opacity: 1;
            right: auto;
            left: 11px;
            top: -50%;
        }
        .iconafterdash:first-of-type::before{
            display: none;
        }
        .iconafterdash:last-of-type::before{
            border-right: 2px solid #5496F0;
        }
    }
}
</style>
